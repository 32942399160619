<template>
  <section class="jci_projects_apply container970">
    <div class="top-banner">
      <img v-if="skillCourseInfo.detail_picture" :src="skillCourseInfo.detail_picture"
        style="width: 100%; height: 100%;object-fit:cover" />
      <el-skeleton-item v-if="loading" variant="image"
        style="width: 100%; height: 230px;background:rgba(255, 255, 255, .2)" />
      <!-- <img v-else src="../../assets/images/don
      ation/second_bg.png" style="width: 100%; height: 100%;object-fit:cover" /> -->
      <div class="project_name" style="padding:0 100px;text-align: center;">
        <h3>{{ skillCourseInfo.project_name }}</h3>
      </div>
    </div>

    <el-card class="table-card mt-3 detail_card" style="padding-top:20px">
      <div class="option_back">
        <el-button type="primary" icon="el-icon-arrow-left" plain @click="backToGallery">Back</el-button>
      </div>
      <section class="d-flex justify-content-between align-items-center mb-3 mt-2">
        <h4>General Project Information</h4>
        <div class="my_tag" v-if='skillCourseInfo.is_has_tag'>
          <img src="@/assets/images/public/project_zan.png" alt="">
          <span class="icon_text">Endorsed by the President</span>
        </div>
      </section>
      <section class="basic_info" style="margin-bottom: 40px;">
        <div class="item d-flex mb-2">
          <span class="left">Publisher</span>
          <div class="right">{{skillCourseInfo.publisher|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Area</span>
          <div class="right">{{skillCourseInfo.area|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">National Organization</span>
          <div class="right">{{skillCourseInfo.national|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2 description">
          <span class="left">Local Organization</span>
          <div class="right">{{skillCourseInfo.local|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2 description">
          <span class="left">Contact person for the project</span>
          <div class="right">{{skillCourseInfo.contact|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Duration of the project</span>
          <div class="right">{{skillCourseInfo.duration|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Is it a RISE project</span>
          <div class="right">{{skillCourseInfo.is_has_project?"Yes":"No"}}</div>
        </div>
        <div class="item d-flex mb-2" v-if="skillCourseInfo.is_has_project">
          <span class="left">RISE Pillars category</span>
          <div class="right">
            <div v-for="(v, i) in skillCourseInfo.project_category_desc" :key="i">{{v}}</div>
          </div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Areas of Opportunity category</span>
          <div class="right">
            <div v-for="(v, i) in skillCourseInfo.area_category_desc" :key="i">{{v}}</div>
          </div>
        </div>
        <div class="item d-flex">
          <span class="left">Year of the project</span>
          <div class="right">{{skillCourseInfo.year|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Project Manager name</span>
          <div class="right">{{skillCourseInfo.project_manager_name|filterNull}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Contact email or phone number</span>
          <div class="right">{{skillCourseInfo.whatsApp_number|filterNull}}</div>
        </div>
        <div class="item d-flex  mb-2">
          <span class="left">Picture of the Project Manager</span>
          <div class="right" v-if="skillCourseInfo.project_manager_photo">
            <el-image :src="skillCourseInfo.project_manager_photo" style="width: 183px; height: 183px" fit="cover"
              :preview-src-list="[skillCourseInfo.project_manager_photo]" :z-index="99999"></el-image>
          </div>
          <div class="right" v-else>-</div>
        </div>
        <div class="item d-flex flex-column mb-3">
          <span class="left_summary">Summary of the project</span>
          <div class="right ml-0" v-html="skillCourseInfo.summary"></div>
        </div>
      </section>

      <!-- 新数据 -->
      <section v-if="is_extended">
        <section class="mb-3">
          <h4>SDG Alignment</h4>
        </section>
        <section class="basic_info">
          <div class="item d-flex mb-2 flex-column">
            <span class="left_summary">Primary Sustainable Development Goal (SDG) Addressed</span>
            <div class="right ml-0">{{skillCourseInfo.primary_sdg_desc|filterNull}}</div>
          </div>
          <div class="item d-flex mb-2 flex-column">
            <span class="left_summary">Secondary SDGs Addressed</span>
            <div class="right ml-0">
              <div v-for="(v, i) in skillCourseInfo.secondary_sdg_desc" :key="i">{{v}}</div>
            </div>
          </div>
          <div class="item d-flex mb-5 flex-column">
            <span class="left_summary">Explain how your project contributes to the selected SDGs</span>
            <div class="right ml-0">{{skillCourseInfo.project_contribute|filterNull}}</div>
          </div>
        </section>
        <section class="mb-3">
          <h4>Project Impact Data</h4>
        </section>
        <section class="basic_info">
          <div class="item d-flex mb-2">
            <span class="left">Number of Beneficiaries</span>
            <div class="right">{{skillCourseInfo.number_beneficiaries|filterNull}}</div>
          </div>
          <h5 class="mb-1">Demographics of Beneficiaries</h5>
          <div class="item d-flex mb-2 ml-3">
            <span class="left">Age Group</span>
            <div class="right ml-0">
              <div v-for="(v, i) in skillCourseInfo.age_group_desc" :key="i">{{v}}</div>
            </div>
          </div>
          <div class="item d-flex mb-2 ml-3">
            <span class="left">Gender</span>
            <div class="right ml-0">
              <div v-for="(v, i) in skillCourseInfo.gender_desc" :key="i">{{v}}</div>
            </div>
          </div>
          <div class="item d-flex mb-2 ml-3">
            <span class="left">Location</span>
            <div class="right ml-0">
              <div v-for="(v, i) in skillCourseInfo.location_desc" :key="i">{{v}}</div>
            </div>
          </div>
          <div class="item d-flex mb-2 ml-3">
            <span class="left">Socio-Economic Background</span>
            <div class="right ml-0">
              <div v-for="(v, i) in skillCourseInfo.socio_economic_background_desc" :key="i">{{v}}</div>
            </div>
          </div>
          <div class="item d-flex mb-2">
            <span class="left">Number of Volunteers Engaged</span>
            <div class="right">{{skillCourseInfo.number_volunteers_engaged|filterNull}}</div>
          </div>
          <div class="item d-flex mb-2">
            <span class="left">Volunteer Activities Breakdown</span>
            <div class="right">
              <div v-for="(v, i) in skillCourseInfo.volunteer_activities_breakdown_desc" :key="i">{{v}}</div>
            </div>
          </div>
          <div class="item d-flex mb-2">
            <span class="left">Total Hours Contributed by Volunteers</span>
            <div class="right">{{skillCourseInfo.total_hours_volunteers|filterNull}}</div>
          </div>
          <h5 class="mb-1 mt-3">Funds Raised</h5>
          <div class="item d-flex mb-2 ml-3">
            <span class="left">Total Project Cost (USD)</span>
            <div class="right ml-0">{{skillCourseInfo.total_project_cost|filterNull}}</div>
          </div>
          <div class="item d-flex mb-2 ml-3">
            <span class="left">Sponsorship Secured (USD)</span>
            <div class="right ml-0">{{skillCourseInfo.sponsorship_secured|filterNull}}</div>
          </div>
          <div class="item d-flex mb-2">
            <span class="left">Partnerships Involved</span>
            <div class="right">
              <div v-for="(v, i) in skillCourseInfo.partnerships_involved_desc" :key="i">{{v}}</div>
            </div>
          </div>
          <div class="item d-flex mb-2 flex-column">
            <span class="left_summary">Have you used the JCI Action Framework to implement this project?</span>
            <div class="right ml-0">{{skillCourseInfo.is_used_jci_framework?"Yes":'No'}}</div>
          </div>
        </section>
        <section class="mb-3 mt-5">
          <h4>Supporting Documentation</h4>
        </section>
        <section class="basic_info">
          <div class="item d-flex mb-2">
            <span class="left">Photos/Videos of the Project</span>
            <div class="right d-flex" v-if="skillCourseInfo.upload_photos_or_videos.length">
              <div class="mr-3 mb-3" v-for="(v, i) in skillCourseInfo.upload_photos_or_videos" :key="i">
                <el-image v-if="v.type == 'image'" :src="v.url" style="width: 183px; height: 183px" fit="cover"
                  :preview-src-list="[v.url]" :z-index="99999"></el-image>
                <video v-else :src="v.url" controls></video>
              </div>
            </div>
            <div class="right" v-else>-</div>
          </div>
          <div class="item d-flex mb-2">
            <span class="left">Project Reports or Testimonials</span>
            <div class="right right_file" v-if="fileType=='jpg'">
              <section class="mr-3 mb-3 d-flex align-content-center">
                <img src="../../assets/images/public/jpg_file.png" alt="" style="width: 28px;height: 28px;">
                <span class="ml-1">{{fileName}}</span>
                <div @click="showViewer=true">
                  <el-button class="ml-2" icon="el-icon-search" size="mini" circle type="primary"></el-button>
                </div>
                <el-image-viewer v-show="showViewer" :url-list="[skillCourseInfo.project_reports_or_testimonials]"
                  :on-close="()=>{showViewer=false}">
                </el-image-viewer>
              </section>
            </div>
            <div class="right right_file" v-else-if="fileType=='jpeg'">
              <section class="mr-3 mb-3 d-flex align-content-center">
                <img src="../../assets/images/public/jpeg_file.png" alt="" style="width: 28px;height: 28px;">
                <span class="ml-1">{{fileName}}</span>
                <div @click="showViewer=true">
                  <el-button class="ml-2" icon="el-icon-search" size="mini" circle type="primary"></el-button>
                </div>
                <el-image-viewer v-show="showViewer" :url-list="[skillCourseInfo.project_reports_or_testimonials]"
                  :on-close="()=>{showViewer=false}">
                </el-image-viewer>
              </section>
            </div>
            <div class="right right_file" v-else-if="fileType=='pdf'">
              <section class="mr-3 mb-3 d-flex align-content-center">
                <img src="../../assets/images/public/pdf_file.png" alt="" style="width: 28px;height: 28px;">
                <span class="ml-1">{{fileName}}</span>
                <el-button class="ml-2" icon="el-icon-search" size="mini" circle type="primary"
                  @click="openUrl(skillCourseInfo.project_reports_or_testimonials)"></el-button>
              </section>
            </div>
            <div class="right" style="word-wrap: break-word ;width: 612px;" v-else>
              {{skillCourseInfo.project_reports_or_testimonials|filterNull}}
            </div>
          </div>
          <div class="item d-flex mb-5">
            <span class="left">Links to Media Coverage / Website</span>
            <div class="right">{{skillCourseInfo.media_link|filterNull}}</div>
          </div>
        </section>
        <section class="mb-3">
          <h4>Future Plans & Sustainability</h4>
        </section>
        <section class="basic_info">
          <div class="item d-flex mb-2 flex-column">
            <span class="left_summary">Is this project scalable or replicable in other JCI Local Organizations?</span>
            <div class="right ml-0">{{skillCourseInfo.is_other_jci?'Yes':'No'}}</div>
          </div>
          <div class="item d-flex mb-2 flex-column" v-if="skillCourseInfo.is_other_jci">
            <span class="left_summary">If Yes, what resources or support would be needed to expand it?</span>
            <div class="right ml-0">{{skillCourseInfo.yes_need_expand|filterNull}}</div>
          </div>
          <div class="item d-flex mb-5 flex-column" v-if="skillCourseInfo.is_other_jci">
            <span class="left_summary">How will this project be sustained long-term?</span>
            <div class="right ml-0">{{skillCourseInfo.how_long_term_desc|filterNull}}</div>
          </div>
        </section>
      </section>
    </el-card>
  </section>
</template>

<script>
  import ElImageViewer from "element-ui/packages/image/src/image-viewer";
  export default {
    data() {
      return {
        skillCourseInfo: {
          detail_picture: ''
        },
        loading: true,
        is_extended: 0,
        fileType: '',
        fileName: '',
        showViewer: false
      }
    },

    components: { ElImageViewer },

    mounted() {
      this.getProjectDetail()
    },

    methods: {
      openUrl(url) {
        window.open(url)
      },

      backToGallery() {
        this.$store.commit("Public/CHANGE_PAGE", this.$route.query.page)
        if (this.$route.query.is_user) {
          if (this.$route.query.is_draft) {
            this.$store.commit("Public/CHANGE_DRAFT", 'gallery')
          }
          this.$router.back()
        } else {
          this.$store.commit("Public/CHANGE_LIST", 'gallery')
          this.$router.push(`/project-gallery`)
        }
      },

      // 获取项目详情
      getProjectDetail() {
        this.loading = true
        this.$http.getProjectDetail({ id: this.$route.query.id }).then((res) => {
          if (res.status == 200) {
            this.skillCourseInfo = res.data
            this.is_extended = res.data.is_extended

            if(this.is_extended) {
              this.fileType = res.data.project_reports_or_testimonials
              let strIndex = this.fileType.lastIndexOf('.');
              if (strIndex === -1) { } else { this.fileType = this.fileType.substring(strIndex + 1) }
  
              this.fileName = res.data.project_reports_or_testimonials
              let nameIndex = this.fileName.lastIndexOf('/');
              if (nameIndex === -1) { } else { this.fileName = this.fileName.substring(nameIndex + 1) }
            }
          }
          setTimeout(() => {
            this.loading = false
          }, 0);
        })
      }

    }
  }
</script>
<style lang="scss" scoped>
  .jci_projects_apply {
    .height_scroll {
      max-height: 700px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .my_tag {
      border: 1.5px solid #EDBE38;
      border-radius: 14.5px;
      padding: 2px 8px;
      text-align: center;
      color: #EDBE38;
      line-height: 24px;
    }

    .top-banner {
      height: 230px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      position: relative;

      div {
        height: 230px;
        width: 100%;
        background: rgba($color: #999, $alpha: 0.3);
        display: flex;
        justify-content: center;
        border-radius: 5px;
        align-items: center;
        position: absolute;

        >h3 {
          color: #fff;
          font-size: 30px;
        }
      }


    }

    .detail_card {
      position: relative;
      overflow: visible;

      .option_back {
        position: absolute;
        display: flex;
        left: -120px;
        top: -1px;
      }
    }

    /deep/ .basic_info {
      .left {
        width: 300px;
      }

      .right {
        color: #999;
        flex: 1;
        margin-left: 1rem;
        flex-wrap: wrap;

        ::v-deep p {
          margin: 0 !important;
          padding: 0 !important;
        }

        img,
        video {
          width: 183px;
          height: 183px;
        }
      }

      .description {

        ul,
        ol {
          padding: 0;
          margin-bottom: 2px;
        }

        ul {
          list-style: disc;
        }
      }
    }

    ::v-deep .table-card .el-card__body {
      padding-top: 0px;
    }

  }

  @media (max-width: 600px) {
    .jci_projects_apply {
      /deep/ .basic_info .item {
        flex-direction: column;
      }

      h4 {
        font-size: 1.2rem;
      }

      .my_tag {
        border: none;
      }

      .icon_text {
        display: none;
      }

      .project_name {
        padding: 0 1.2rem !important;

        h3 {
          font-size: 1.5rem !important;
          word-break: keep-all !important;
        }
      }

      .right {
        width: 100% !important;
        margin-left: 0 !important;
      }

      .right_file {
        span {
          word-break:break-all;
        }
      }

    }
  }
</style>